/*
 * @Date: 2021年11月18日10:21:25
 * @Author: Jeason
 * @LastEditors: Jeason
 * @LastEditTime: 2021年11月18日10:21:29
 * @Description: 意见反馈接口
 * @FilePath: \maintenancef:\sass_web\maintenance_expert\src\api\feedback.js
 */
import server from '@/request';
export default {
  // 意见反馈列表
  feedbackQuery (params) {
    return server.post('/xiaozi-xmb/wireless/saas/feedback/queryPage', params);
  },
  // 意见反馈新增
  feedbackAdd (params) {
    return server.post('/xiaozi-xmb/wireless/saas/feedback/add', params);
  },
  // 意见反馈删除
  feedbackDelete (params) {
    return server.post('/xiaozi-xmb/wireless/saas/feedback/delete', params);
  },
  // 意见反馈详情
  feedbackDetail (params) {
    return server.post('/xiaozi-xmb/wireless/saas/feedback/queryDetail', params);
  },
  // 意见反馈提交
  feedbackSubmit (params) {
    return server.post('/xiaozi-xmb/wireless/saas/feedback/submit', params);
  },
  // 意见反馈编辑
  feedbackUpdate (params) {
    return server.post('/xiaozi-xmb/wireless/saas/feedback/update', params);
  },
};
