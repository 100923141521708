<!--
 * @Date: 2021年11月18日10:34:27
 * @Author: Jeason
 * @LastEditors: Jeason
 * @LastEditTime: 2021年11月18日10:34:31
 * @Description: 意见反馈 新增 编辑 详情
 * @FilePath:
-->
<template>
  <div class='JZContainer content_block'>
    <el-form :model='JZForm'  class="content_block" ref="JZForm" :rules='JZFormRules' label-width='120px' label-position='right' >
     <el-form-item label='意见反馈模块：' prop='module' style="width:1000px">
        <template v-if="isDisabled">{{handleResultForm.moduleDesc || '--'}}</template>
        <el-select v-model="JZForm.module"  v-else placeholder="请选择反馈模块">
          <el-option
            v-for="mod in moduleLists"
            :key="mod.value"
            :label="mod.label"
            :value="mod.value">
          </el-option>
        </el-select>
      </el-form-item>
      <el-form-item label='手机号：' prop='mobilephone' style="width:1000px">
        <span slot="label">
          手机号：
          <el-tooltip class="item" effect="dark" content="若需要客服直接联系您，请留下正确的手机号，方便我们联系到您。" placement="top-start">
            <i class="el-icon-warning" />
          </el-tooltip>
        </span>
        <template v-if="isDisabled">{{handleResultForm.mobilephone || '--'}}</template>
        <el-input placeholder="请输入手机号" v-else   v-model.trim="JZForm.mobilephone" clearable maxlength='11' show-word-limit></el-input>
      </el-form-item>
      <el-form-item label='问题描述：' prop='remark' style="width:1000px">
        <template v-if="isDisabled">{{handleResultForm.remark || '--'}}</template>
        <el-input type='textarea' v-else placeholder="请输入问题描述"  v-model.trim="JZForm.remark" clearable maxlength='100' show-word-limit></el-input>
      </el-form-item>
      <el-form-item label="问题图片：" prop="fileList" style="width:1000px">
        <template v-if="!fileLists.length&&isDisabled">无</template>
        <el-image v-else-if="fileLists.length&&isDisabled" :preview-src-list='imgUrlLists' :key="index" v-for="(item, index) in fileLists" :z-index='index' fit="fill"  style="width: 60px; height: 60px;vertical-align: top;margin-right:10px" :src='item.url' ></el-image>
        <Upload
          v-model="fileLists"
          label="问题图片："
          :maxSize="5"
          v-else
          size="small"
          :disabled="isDisabled"
          @success="handleUpload"
          @remove="handleRemove"
          :limit="10"/>
      </el-form-item>
      <hr v-if="isDisabled&&handleResultForm.handleResult" style="margin-bottom:30px;border-color:rgba(36, 40, 50, 0.2)" />
      <el-form-item label='处理结果：' v-if="isDisabled&&handleResultForm.handleResult" style="width:1000px">
        {{handleResultForm.handleResult}}
      </el-form-item>
      <el-form-item label='处理人：' v-if="isDisabled&&handleResultForm.handleResult" style="width:1000px">
        {{handleResultForm.handleUserName || '--'}}
      </el-form-item>
      <el-form-item >
        <el-button type="default" plain @click="goBack" >取消</el-button>
        <el-button type="warning" v-if="!isDisabled"  @click="handleSave('2')"  >{{$route.query.isStatus === 'isEditing' ? '更新' : '保存'}}并提交</el-button>
        <el-button type="primary" v-if="!isDisabled"  @click="handleSave('1')"  >{{$route.query.isStatus === 'isEditing' ? '更新' : '保存'}}</el-button>
      </el-form-item>
    </el-form>
  </div>
</template>

<script>
import Upload from '@/components/common/upload';
import Feedback from '@/api/feedback';
export default {
  name: 'FeedbackInfo',
  components: {
    Upload,
  },
  created () {
    this.initPage();
  },
  methods: {
    initPage () {
      let userInfo = JSON.parse(this.$local.get('userInfo'));
      this.mobilephone = userInfo.phoneNumber;
      if (this.$route.query.isStatus) {
        this.getDetailInfo();
      } else {
        this.JZForm = this.$options.data().JZForm;
        this.JZForm.mobilephone = this.mobilephone;
        this.$nextTick(() => {
          this.$refs.JZForm.clearValidate();
        });
      }
    },
    getDetailInfo () {
      Feedback.feedbackDetail({id: this.$route.query.id}).then((jsonData) => {
        if (jsonData.heads.code === 200) {
          let currentInfo = jsonData.body;
          let fileLists = currentInfo.fileList;
          fileLists = fileLists.map(item=>{
            return {
              ...item,
              attachName: item.name,
            };
          });
          this.JZForm = {
            module: currentInfo.module,
            mobilephone: currentInfo.mobilephone,
            remark: currentInfo.remark,
            status: currentInfo.status,
            fileList: fileLists,
          };
          this.fileLists = fileLists;
          this.imgUrlLists = fileLists.map(item => item.url);
          this.handleResultForm = {
            moduleDesc: currentInfo.moduleDesc,
            mobilephone: currentInfo.mobilephone,
            remark: currentInfo.remark,
            handleResult: currentInfo.handleResult || '',
            handlerUserCode: currentInfo.handlerUserCode,
            handleUserName: `${currentInfo.handleUserName || ''}   ${currentInfo.handlerUserCode || '测试编码'}`,
          };
          this.$nextTick(() => {
            this.$refs.JZForm.clearValidate();
          });
        } else {
          this.$JZShowAlert(jsonData.heads.message, 'error');
        }
      });
    },
    handleSave (status) {
      this.$refs.JZForm.validate((valid) => {
        if (valid) {
          let currentParams = {
            ...this.JZForm,
            status: status,
          };
          if (this.$route.query.id) {
            Feedback.feedbackUpdate({...currentParams, id: this.$route.query.id}).then((jsonData) => {
              if (jsonData.heads.code === 200) {
                this.JZForm = this.$options.data().JZForm;
                this.$nextTick(() => {
                  this.$refs.JZForm.clearValidate();
                });
                this.goBack();
              } else {
                this.$JZShowAlert(jsonData.heads.message, 'error');
              }
            });
          } else {
            Feedback.feedbackAdd(currentParams).then((jsonData) => {
              if (jsonData.heads.code === 200) {
                this.JZForm = this.$options.data().JZForm;
                this.$nextTick(() => {
                  this.$refs.JZForm.clearValidate();
                });
                this.goBack();
              } else {
                this.$JZShowAlert(jsonData.heads.message, 'error');
              }
            });
          }
        }
      });
    },
    goBack () {
      this.$tabs.close();
    },
    handleUpload (file) {
      this.JZForm.fileList.push({
        name: file.attachName,
        attachName: file.attachName,
        url: file.url,
        uid: file.uid,
      });
    },
    handleRemove (file) {
      let fileList = JSON.parse(JSON.stringify(this.JZForm.fileList));
      let index = fileList.findIndex(t => t.url === file.url || t.uid === file.uid);
      this.JZForm.fileList.splice(index, 1);
    },
  },
  computed: {
    isDisabled () {
      return this.$route.query.isStatus === 'isViewing';
    },
  },
  data () {
    return {
      mobilephone: '',
      JZForm: {
        module: '',
        mobilephone: '',
        remark: '',
        status: '',
        fileList: [],
      },
      imgUrlLists: [],
      handleResultForm: {
        handleResult: '',
        handlerUserCode: '',
        submitUserName: '',
      },
      fileLists: [],
      JZFormRules: {
        module: [
          {required: true, message: '请选择反馈模块', trigger: ['blur', 'change']},
        ],
        remark: [
          {required: true, message: '请输入问题描述', trigger: ['blur', 'change']},
        ],
        mobilephone: [
          {pattern: /^1\d{10}$/, message: '手机号格式不正确', trigger: ['blur', 'change']},
        ],
      },
      moduleLists: [
        {
          label: '工单中心',
          value: '1',
        },
        {
          label: '医院管理',
          value: '2',
        },
        {
          label: '设备管理',
          value: '3',
        },
        {
          label: '系统管理',
          value: '4',
        },
      ],
    };
  },
};
</script>
<style scoped lang='scss'>
/* @import url(); 引入css类 */

</style>
